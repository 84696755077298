import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DefinitionsComponent } from './pages/definitions/definitions.component';
import { TrendsComponent } from './pages/trends/trends.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DashboardActivityComponent } from './pages/dashboard/dashboard-activity/dashboard-activity.component';
import { DashboardMainComponent } from './pages/dashboard/dashboard-main/dashboard-main.component';
import { DashboardFitnessComponent } from './pages/dashboard/dashboard-fitness/dashboard-fitness.component';
import { DashboardPowerComponent } from './pages/dashboard/dashboard-power/dashboard-power.component';
import { isImperial, PrivacyPolicyComponent, TermsAndConditionsComponent } from '@carol-nx/utils';
import {
  ChartType,
  COOKIE_POLICY_URL,
  NeedRideTypes,
  PRIVACY_POLICY_URL,
  RideTypes,
  TERMS_OF_WEBSITE_URL
} from '@carol-nx/data';
import { AuthGuard } from './guards/auth.guard';
import { NotAuthGuard } from './guards/not-auth.guard';
import { DashboardCardsComponent } from './components/dashboard-cards/dashboard-cards.component';


const defaultRideTypes: NeedRideTypes[] = [
  {rideType: RideTypes.IntenseRides},
  {rideType: RideTypes.IntermediatesRides},
  {rideType: RideTypes.EnergisersRides},
  {rideType: RideTypes.RapidFatBurnRides},
  {rideType: RideTypes.FreeCustomRides},
  {rideType: RideTypes.FitnessTestsRides}
];
const fatBurnRideTypes: NeedRideTypes[] = [
  {rideType: RideTypes.RapidFatBurn30Rides},
  {rideType: RideTypes.RapidFatBurn45Rides},
  {rideType: RideTypes.RapidFatBurn60Rides}
];
const dashboardRoutes = [
  {
    path: '',
    component: DashboardMainComponent,
    data: {pageTitle: 'DASHBOARD_INFO_HEADER'}
  },
  {
    path: 'activity',
    component: DashboardActivityComponent,
    data: {pageTitle: 'DASHBOARD_INFO_ACTIVITY'}
  },
  {
    path: 'fitness',
    component: DashboardFitnessComponent,
    data: {pageTitle: 'DASHBOARD_INFO_FITNESS'}
  },
  {
    path: 'burn',
    children: [
      {
        path: '',
        component: DashboardCardsComponent,
        data: {
          pageTitle: 'DASHBOARD_INFO_CALORIES', neededRideTypes: [
            { rideType: RideTypes.REHIT, route: './rehit' },
            {rideType: RideTypes.RapidFatBurnRides, route: './fatburn'},
            { rideType: RideTypes.FreeCustomRides, route: './free-custom' },
            {rideType: RideTypes.FitnessTestsRides}
          ], chartType: ChartType.CaloriesInclEPOC, repeatCount: 2
        },
      },
      {
        path: 'rehit',
        component: DashboardCardsComponent,
        data: {
          pageTitle: 'DASHBOARD_INFO_BURN_REHIT_RIDE',
          neededRideTypes: [
            { rideType: RideTypes.IntenseRides },
            {rideType: RideTypes.Intermediate3x15},
            { rideType: RideTypes.IntermediateRides },
            {rideType: RideTypes.Energiser3x10},
            { rideType: RideTypes.EnergiserRides }
          ],
          chartType: ChartType.CaloriesInclEPOC,
          repeatCount: 6
        }
      },
      {
        path: 'fatburn',
        component: DashboardCardsComponent,
        data: {
          pageTitle: 'DASHBOARD_INFO_BURN_FAT_BURN_CALORIES',
          neededRideTypes: fatBurnRideTypes,
          chartType: ChartType.CaloriesInclEPOC,
          repeatCount: 2
        }
      },
      {
        path: 'free-custom',
        component: DashboardCardsComponent,
        data: {
          pageTitle: 'DASHBOARD_INFO_BURN_FREE_CUSTOM_CALORIES',
          neededRideTypes: [
            { rideType: RideTypes.FreeRides },
            { rideType: RideTypes.DrAdamsonIntervals },
            { rideType: RideTypes.GibalaIntervalsRides },
            { rideType: RideTypes.CoyleIntervalsRides }
          ],
          chartType: ChartType.CaloriesInclEPOC,
          repeatCount: 2
        }
      }
    ]
  },
  {
    path: 'power',
    component: DashboardPowerComponent,
    data: {pageTitle: 'DASHBOARD_INFO_POWER'}
  },
  {
    path: 'energy',
    children: [
      {
        path: '',
        component: DashboardCardsComponent,
        data: {
          pageTitle: 'DASHBOARD_INFO_ENERGY', neededRideTypes: [
            { rideType: RideTypes.REHIT, route: './rehit' },
            {rideType: RideTypes.RapidFatBurnRides, route: './fatburn'},
            { rideType: RideTypes.FreeCustomRides, route: './free-custom' },
            {rideType: RideTypes.FitnessTestsRides}
          ], chartType: ChartType.EnergyOutput, repeatCount: 2
        },
      },
      {
        path: 'rehit',
        component: DashboardCardsComponent,
        data: {
          pageTitle: 'DASHBOARD_INFO_ENERGY_REHIT_RIDE',
          neededRideTypes: [
            { rideType: RideTypes.IntenseRides },
            {rideType: RideTypes.Intermediate3x15},
            { rideType: RideTypes.IntermediateRides },
            { rideType: RideTypes.Energiser3x10 },
            { rideType: RideTypes.EnergiserRides }
          ],
          chartType: ChartType.EnergyOutput,
          repeatCount: 6
        }
      },
      {
        path: 'fatburn',
        component: DashboardCardsComponent,
        data: {
          pageTitle: 'DASHBOARD_INFO_BURN_FAT_BURN_ENERGY',
          neededRideTypes: fatBurnRideTypes,
          chartType: ChartType.EnergyOutput,
          repeatCount: 2
        }
      },
      {
        path: 'free-custom',
        component: DashboardCardsComponent,
        data: {
          pageTitle: 'DASHBOARD_INFO_ENERGY_FREE_CUSTOM_RIDE',
          neededRideTypes: [
            { rideType: RideTypes.FreeRides },
            { rideType: RideTypes.DrAdamsonIntervals },
            { rideType: RideTypes.GibalaIntervalsRides },
            { rideType: RideTypes.CoyleIntervalsRides }
          ],
          chartType: ChartType.EnergyOutput,
          repeatCount: 2
        }
      }
    ]
  },
  {
    path: 'peakpower',
    component: DashboardCardsComponent,
    data: {
      pageTitle: 'DASHBOARD_INFO_TITLE_PEAK_POWER',
      neededRideTypes: defaultRideTypes,
      chartType: ChartType.PeakPower,
      repeatCount: 3
    }
  },
  {
    path: 'peakpowerperlb',
    component: DashboardCardsComponent,
    data: {
      pageTitle: isImperial() ? 'DASHBOARD_INFO_POWER_PEAK_POWER_PER_LB' : 'DASHBOARD_INFO_POWER_PEAK_POWER_PER_KG',
      neededRideTypes: defaultRideTypes,
      chartType: isImperial() ? ChartType.PeakPowerPerLB : ChartType.PeakPowerPerKG,
      repeatCount: 3
    }
  },
  {
    path: 'peakhr',
    component: DashboardCardsComponent,
    data: {
      pageTitle: 'DASHBOARD_INFO_FITNESS_PEAK_HEART_RATE',
      neededRideTypes: defaultRideTypes,
      chartType: ChartType.PeakHeartRate,
      repeatCount: 3
    }
  }
];

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main'
  },
  {
    path: 'main',
    component: DashboardComponent,
    children: dashboardRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: 'client/:clientId/main',
    component: DashboardComponent,
    children: dashboardRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/rides/rides.module').then(m => m.RidesModule),
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.RIDES'}
  },
  {
    path: 'bookings',
    loadChildren: () => import('./modules/bookings/bookings.module').then(m => m.BookingsModule),
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.BOOKINGS'}
  },
  {
    path: 'definitions',
    component: DefinitionsComponent,
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.DEFINITIONS'}
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.CONTACT'}
  },
  {
    path: 'trends',
    component: TrendsComponent,
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.TRENDS'}
  },
  {
    path: 'client/:clientId/trends',
    component: TrendsComponent,
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.TRENDS'}
  },
  {
    path: 'trainers',
    loadChildren: () => import('./modules/trainers/trainers.module').then(m => m.TrainersModule),
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.TRAINERS'}
  },
  {
    path: 'clients',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule),
    data: {pageTitle: 'TITLES.CLIENTS'}
  },
  {
    path: 'preferences',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/preferences/preferences.module').then(m => m.PreferencesModule),
    data: {pageTitle: 'TITLES.PREFERENCES'}
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.ADMIN'}
  },
  {
    path: 'leaderboards',
    loadChildren: () => import('./modules/leaderboard/leaderboard.module').then(m => m.LeaderboardModule),
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.LEADERBOARDS'}
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NotAuthGuard]
  },
  {
    path: 'membership',
    loadChildren: () => import('./modules/license-owner/license-owner.module').then(m => m.LicenseOwnerModule),
    canActivate: [AuthGuard],
    data: {pageTitle: 'TITLES.MEMBERSHIP'}
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./modules/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
  },
  {
    path: TERMS_OF_WEBSITE_URL,
    component: TermsAndConditionsComponent,
    canActivate: [NotAuthGuard]
  },
  {
    path: COOKIE_POLICY_URL,
    redirectTo: `/${PRIVACY_POLICY_URL}#cookies`
  },
  {
    path: PRIVACY_POLICY_URL,
    component: PrivacyPolicyComponent,
    canActivate: [NotAuthGuard]
  },
  {
    path: '**',
    redirectTo: '/404'
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: {pageTitle: '404'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always',
    anchorScrolling: 'enabled',
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
