import { RideTypes } from '@carol-nx/data';
import { PowerService } from '@carol-nx/services';

export class TabTypesComponent {
  public rideSubTypes = {
    [RideTypes.REHIT]: [
      RideTypes.REHIT,
      RideTypes.IntenseRides,
      RideTypes.Intermediate3x15,
      RideTypes.IntermediateRides,
      RideTypes.Energiser3x10,
      RideTypes.EnergiserRides
    ],
    [RideTypes.RapidFatBurnRides]: [
      RideTypes.RapidFatBurnRides,
      RideTypes.RapidFatBurn30Rides,
      RideTypes.RapidFatBurn45Rides,
      RideTypes.RapidFatBurn60Rides
    ],
    [RideTypes.FreeCustomRides]: [
      RideTypes.FreeCustomRides,
      RideTypes.FreeRides,
      RideTypes.DrAdamsonIntervals,
      RideTypes.GibalaIntervalsRides,
      RideTypes.CoyleIntervalsRides
    ]
  };
  protected subTypesNames: Partial<Record<RideTypes, string>> = this.powerService.subTypesNames;//todo remove
  protected tabNames: Partial<Record<RideTypes, string>> = {
    [RideTypes.REHIT]: 'REHIT',
    [RideTypes.IntenseRides]: `REHIT - ${this.powerService.subTypesNames.INTENSE}`,
    [RideTypes.Intermediate3x15]: `REHIT - ${this.powerService.subTypesNames.INTERMEDIATE_3X15}`,
    [RideTypes.IntermediateRides]: `REHIT - ${this.powerService.subTypesNames.INTERMEDIATE}`,
    [RideTypes.Energiser3x10]: `REHIT - ${this.powerService.subTypesNames.ENERGISER_3X10}`,
    [RideTypes.EnergiserRides]: `REHIT - ${this.powerService.subTypesNames.ENERGISER}`,
    [RideTypes.RapidFatBurnRides]: 'Fat Burn',
    [RideTypes.RapidFatBurn30Rides]: this.powerService.subTypesNames.FAT_BURN_30,
    [RideTypes.RapidFatBurn45Rides]: this.powerService.subTypesNames.FAT_BURN_45,
    [RideTypes.RapidFatBurn60Rides]: this.powerService.subTypesNames.FAT_BURN_60,
    [RideTypes.FreeCustomRides]: 'Free & Custom',
    [RideTypes.FreeRides]: `${this.powerService.subTypesNames.FREE} Rides`,
    [RideTypes.DrAdamsonIntervals]: `Custom ${this.powerService.subTypesNames.DR_ADAMSON_INTERVALS}`,
    [RideTypes.GibalaIntervalsRides]: `Custom ${this.powerService.subTypesNames.GIBALA_INTERVALS}`,
    [RideTypes.CoyleIntervalsRides]: `Custom ${this.powerService.subTypesNames.COYLE_INTERVALS}`
  };

  constructor(protected powerService: PowerService) {
  }

  protected setParentTypeFilterMenu(rideType: RideTypes) {
    if (!this.powerService.getSubTypes().getValue()?.includes(rideType))
      this.powerService.setSubTypes(this.rideSubTypes[rideType]);
  }

  protected setSubTypeFilterMenu(rideType: RideTypes) {
    if (rideType !== this.powerService.getSubTypeSelected().getValue())
      this.powerService.setSubTypeSelected(rideType);
  }
}
