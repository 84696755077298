import { Injectable } from '@angular/core';
import { RideTypes } from '@carol-nx/data';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PowerService {
  private subTypes: BehaviorSubject<RideTypes[]> = new BehaviorSubject(undefined);
  private subTypeSelected: BehaviorSubject<RideTypes> = new BehaviorSubject(undefined);
  private static _subTypesNames: Partial<Record<RideTypes, string>> = {
    [RideTypes.REHIT]: 'All',
    [RideTypes.IntenseRides]: 'Intense (2 x 20s)',
    [RideTypes.Intermediate3x15]: 'Intermediate (3 x 15s)',
    [RideTypes.IntermediateRides]: 'Intermediate (2 x 15s)',
    [RideTypes.Energiser3x10]: 'Energizer (3 x 10s)',
    [RideTypes.EnergiserRides]: 'Energizer (2 x 10s)',
    [RideTypes.RapidFatBurnRides]: 'All',
    [RideTypes.RapidFatBurn30Rides]: 'Fat Burn 30',
    [RideTypes.RapidFatBurn45Rides]: 'Fat Burn 45',
    [RideTypes.RapidFatBurn60Rides]: 'Fat Burn 60',
    [RideTypes.FreeCustomRides]: 'All',
    [RideTypes.FreeRides]: 'Free',
    [RideTypes.DrAdamsonIntervals]: 'Sprint Intervals (10 x 6s)',
    [RideTypes.GibalaIntervalsRides]: 'Sprint Intervals (3 x 20s)',
    [RideTypes.CoyleIntervalsRides]: 'Sprint Intervals (30 x 4s)'
  };

  constructor() {
  }

  public static getNeededPowerTitle(rideType: RideTypes) {
    switch (rideType) {
      case RideTypes.IntenseRides:
        return 'DASHBOARD_INFO_BURN_INTENSE_RIDE';
      case RideTypes.IntermediatesRides:
        return 'DASHBOARD_INFO_BURN_INTERMEDIATE_RIDE';
      case RideTypes.IntermediateRides:
        return 'DASHBOARD_INFO_BURN_INTERMEDIATE_2X15_RIDE';
      case RideTypes.Intermediate3x15:
        return 'DASHBOARD_INFO_BURN_INTERMEDIATE_3X15_RIDE';
      case RideTypes.EnergisersRides:
        return 'DASHBOARD_INFO_BURN_ENERGISER_RIDE';
      case RideTypes.EnergiserRides:
        return 'DASHBOARD_INFO_BURN_ENERGISER_2X10_RIDE';
      case RideTypes.Energiser3x10:
        return 'DASHBOARD_INFO_BURN_ENERGISER_3X10_RIDE';
      case RideTypes.RapidFatBurnRides:
        return 'DASHBOARD_INFO_BURN_FAT_BURN_RIDE';
      case RideTypes.RapidFatBurn30Rides:
        return 'DASHBOARD_INFO_BURN_FAT_BURN_RIDE_30';
      case RideTypes.RapidFatBurn45Rides:
        return 'DASHBOARD_INFO_BURN_FAT_BURN_RIDE_45';
      case RideTypes.RapidFatBurn60Rides:
        return 'DASHBOARD_INFO_BURN_FAT_BURN_RIDE_60';
      case RideTypes.FreeCustomRides:
        return 'DASHBOARD_INFO_BURN_FREE_CUSTOM_RIDE';
      case RideTypes.FitnessTestsRides:
      case RideTypes.FtpTest20Rides:
        return 'DASHBOARD_INFO_BURN_ENDURANCE';
      case RideTypes.REHIT:
        return 'SPEEDOMETER_INFO_REHIT';
      case RideTypes.FreeRides:
        return 'DASHBOARD_INFO_ACTIVITY_FREE_RIDE';
      case RideTypes.DrAdamsonIntervals:
        return 'DASHBOARD_INFO_BURN_DR_ADAMSON';
      case RideTypes.GibalaIntervalsRides:
        return 'DASHBOARD_INFO_BURN_GIBALA';
      case RideTypes.CoyleIntervalsRides:
        return 'DASHBOARD_INFO_BURN_COYLE';
    }
  }

  static get subTypesNames() {
    return this._subTypesNames;
  }

  get subTypesNames() {
    return PowerService._subTypesNames;
  }

  public getSubTypeName(rideType: RideTypes) {
    return this.subTypesNames[rideType];
  }

  public getSubTypes() {
    return this.subTypes;
  }

  public getSubTypeSelected() {
    return this.subTypeSelected;
  }

  public setSubTypes(subTypes: RideTypes[]) {
    this.subTypes.next(subTypes);
  }

  public setSubTypeSelected(subType: RideTypes) {
    this.subTypeSelected.next(subType);
  }
}
